import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class QueryParamsServiceService {

  private queryParams: any = {};

  constructor() { }

  setParams(params: any) {
    this.queryParams = { ...params };
  }

  getParams() {
    return this.queryParams;
  }
}
