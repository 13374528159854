import {Router} from '@angular/router';
import {Injectable, OnInit} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {LocalStorageService} from '../storage/local-storage.service';
import {io} from 'socket.io-client';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {switchMap} from 'rxjs/operators';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {environment} from '../../../environments/environment';

const moment = require('moment');

@Injectable()
export class AuthService implements OnInit {

  private baseUrl = environment.apiBaseUrl;

  todayapts: number;
  yerterDayapts: number;
  tommorrowApts: number;
  totalRevenueGenerated: number;
  PharmacyRevenueGenerated: number;
  filterType = 'Month';
  filterType1 = 'Month';
  totalAmountReceived: number;
  totalAmountPending: number;
  tillMonthApts: any;
  private booking_socket: any;
  private queue_socket: any;
  private queue_socket_remove: any;
  private video_call_socket: any;
  private adds_socket: any;
  private scrollingMessageNamespace: any;
  private queueUpdateNamespace: any;
  menuLoader = new BehaviorSubject<string>('OrgDetails');
  pharmacySubject = new Subject<string>();
  orgGrpSubject = new Subject<string>();
  pharmacyRefresh = new Subject<string>();
  callOrganization = new Subject<string>();


  public userData: any = {
    auth: {},
    organizationGroupList: [],
    doctorList: [],
    SelectedOrg: '',
    SelectedOrgGrp: '',
    patientList: [],
    StaffList: [],
    serviceList: [],
    dashboard: {},
    managerDashboard: {},
    timeOffList: [],
    overTime: [],
  };
  public selectedOrgId: any = '';
  INACTIVITY_PERIOD: number = 24 * 60 * 60 * 1000;
  private timeout: any;
  public countries = [
    '+91'
  ]
  organizationGroup: any;
  public baseURL = `${this.baseUrl}/`;

  constructor(private http: HttpClient, public router: Router, private storage: LocalStorageService, public toastr: ToastrService,
              private modalService: NgbModal) {
    // @ts-ignore
    this.booking_socket = io(`${this.baseUrl}/booking`);
    this.queue_socket = io(`${this.baseUrl}/queue`);
    this.queue_socket_remove = io(`${this.baseUrl}/queueRemove`);
    this.video_call_socket = io(`${this.baseUrl}/videoCall`);
    this.adds_socket = io(`${this.baseUrl}/campaign`);
    this.queueUpdateNamespace = io(`${this.baseUrl}/queueUpdate`);
    this.scrollingMessageNamespace = io(`${this.baseUrl}/doctors`);
  }

  ngOnInit(): void {
    this.organizationGroup = JSON.parse(localStorage.getItem('organizationGroup')).data;
    this.storage.getData('pulse-right').then(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.storage.watchStorage().subscribe(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
  }

  // TODO MJ - Still needs work as we need to disable back navigation on this website
  async checkLocalData() {
    const localData = new Promise(async (resolve, reject) => {
      await this.storage.getData('pulse-right').then(res => {
        resolve(res);
      });
    });
    return localData;
  }

// api call to delete User-Service
  deleteUserService(serviceId, user, doctorId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.delete(this.baseURL + 'service/userService/delete/' + serviceId, {headers}).toPromise().then((res) => {
      return res;
    });
  }

// api call to register patient
  registerPatient(newPatient, user) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(this.baseURL + 'users/register', newPatient, {headers}).toPromise().then((res) => {
      return res;
    }).catch(err => {
      return err;
    });
  }


// api call to register Staff
  registerStaff(newUser, user) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.post(this.baseURL + 'users/register', newUser, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

// api call to register Doctor
  registerDoctor(user) {
    return this.http.post(this.baseURL + 'users/register', user).toPromise().then((res) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

// api call to fetch MyApponintments
  async fetchMyAppointments(user: any, selectedUser: string, userType = 'Doctor') {
    this.userData = user;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return await this.http.get(this.baseURL + 'booking/v2/' + userType + '/' + selectedUser + '/' + this.userData.SelectedOrg, {headers}).toPromise().then(async (appointmentList: any) => {
      return appointmentList;
    });
  }

// api call to create user service
  createUserService(userService, user, doctorId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    const body = {
      cost: userService.cost,
      timeSlot: userService.timeSlot,
      startTime: userService.startTime,
      endTime: userService.endTime,
      serviceDays: userService.serviceDays,
      service: userService.services,
      organization: this.userData.SelectedOrg,
      status: userService.status,
    }
    return this.http.post(this.baseURL + 'service/userService/create/' + doctorId, body, {headers}).toPromise().then((res) => {
      return res;
    }).catch((err) => {
      return err;
    })
  }

  // api call to change password
  ChangePassword(body, user) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.patch(this.baseURL + 'users/' + user.auth.data._id, body, {headers}).toPromise().then(
      (res) => {
        return res;
      })
  }

// api call to fetch orgGroup on URL
  async fetch_urlbased_org(body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return await this.http.post(this.baseURL + 'org/urlBasedOnOrgGrp', body, {headers}).toPromise().then((resp: any) => {
      localStorage.setItem('orgGroupId', resp.data._id);
      // this.getSelectedOrgGrpId(resp.data._id);
      return resp;
    }).catch(err => {
      return err;
    })

  }

  // api call to bookappointment
  bookAppointment(appointmentDetails, user, prepaid, service) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    const body = {
      name: appointmentDetails.name,
      date: appointmentDetails.date,
      time: appointmentDetails.time,
      service: appointmentDetails.service,
      message: appointmentDetails.message,
      status: true,
      user: appointmentDetails.user,
      doctor: appointmentDetails.doctor,
      organization: this.userData.SelectedOrg
    };
    if (prepaid) {
      body.status = false;
    } else {
      body.status = true;
    }
    if (service.name.trim() === 'Video Consultation') {
      return this.http.post(this.baseURL + 'booking/create' + '?pageEndPoint=' + window.location.origin + '/video-call', body, {headers}).toPromise().then((res) => {
        return res;
      });
    } else {
      return this.http.post(this.baseURL + 'booking/create', body, {headers}).toPromise().then((res) => {
        return res;
      });
    }
  }

// websocket Integration to perform auto refresh Booking
  onSlotBooked(): Observable<any> {
    return new Observable((observer: any) => {
      this.booking_socket?.on('message', (data) => {
        observer.next(data);
      });
    });
  }


// websocket Integration to perform auto refresh Queue System
  onQueuePush(): Observable<any> {
    return new Observable((observer: any) => {
      this.queue_socket?.on('message', (data) => {
        observer.next(data);
      });
    });
  }

// emit booking result
  emitEvent(eventName: string, data: string) {
    this.booking_socket.emit(eventName, data);
  }

  // emit queue result
  queue_emit_Event(eventName: string, data: string) {
    this.queue_socket.emit(eventName, data);
  }


  // emit queue position update for socket

  queue_emit_position(eventName: string, data: string) {
    this.queueUpdateNamespace.emit(eventName, data);
  }

  // emit doctor availability message

  doctor_availability_message(eventName: string, data: string) {
    this.scrollingMessageNamespace.emit(eventName, data);
  }


  // Observing  queue removals
  onQueuePop(): Observable<any> {
    return new Observable((observer: any) => {
      this.queue_socket_remove?.on('message', (data: any[]) => {
        observer.next(data);
      });
    });
  }

  // Observing the Queue position update via socket

  OnQueue_position_update(): Observable<any> {
    return new Observable((observer: any) => {
      this.queueUpdateNamespace?.on('message', (data: any[]) => {
        observer.next(data);
      });
    });
  }


  chech_doctor_availability_message(): Observable<any> {
    return new Observable((observer: any) => {
      this.scrollingMessageNamespace?.on('message', (data: any[]) => {
        observer.next(data);
      });
    });
  }

  catchPongResponse() {
    return new Observable((observer: any) => {
      this.queue_socket_remove?.on('pong', (data: any[]) => {
        observer.next(data);
      });
    });
  }

  // emit queue Removal
  queue_emit_removeQueue(eventName: string, data: string) {
    this.queue_socket_remove.emit(eventName, data);
  }

  callMe(eventName) {
    this.queue_socket_remove.emit(eventName);
  }


  // video_call_emit(eventName, roomId) {
  //   this.video_call_socket.emit(eventName, {'roomId': roomId, 'socketId': this.video_call_socket.id});
  // }
  //
  //
  // OnvideoCall(): Observable<any> {
  //   return new Observable((observer: any) => {
  //     this.video_call_socket?.on('message', (data) => {
  //       observer.next(data);
  //     });
  //   });
  // }
  //
  // Call_disconnect(): Observable<any> {
  //   return new Observable((observer: any) => {
  //     this.video_call_socket?.on('disconnect', (data) => {
  //       observer.next(data);
  //     });
  //   });
  // }

  // api call to reschedule appointment
  rescheduleAppointment(user, appointmentData, appointmentId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    const body = appointmentData;
    return this.http.patch(this.baseURL + 'booking/' + appointmentId, body, {headers}).toPromise().then((res) => {
      // console.log(res);
      return res;
    });
  }

  // api call to staff checkin
  updateBooking_checkin(user, appointment, body) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.patch(this.baseURL + 'booking/' + appointment._id, body, {headers}).toPromise().then((res) => {
      // console.log(res);
      return res;
    });
  }

  // api call to edit doctor
  editDoctor(user, userId, data) {
    this.userData = user;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.patch(this.baseURL + 'users/' + userId, data, {headers}).toPromise().then((res) => {
      return res;
    })
  }

  // api call to edit Staff
  editStaff(user, staffId, data) {
    this.userData = user;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.patch(this.baseURL + 'users/' + staffId, data, {headers}).toPromise().then((res) => {
      return res;
    })
  }

  // api call to edit Patient
  editPatient(body: any, user: any, patientId) {
    this.userData = user;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    // TODO enable the API call only after the body and structure is fixed to avoid data corruption
    return this.http.patch(this.baseURL + 'users/' + patientId, body, {headers}).toPromise().then((res) => {
      return res;
    }).catch(err => {
      return err;
    })
  }

  // api call to edit Global Services
  editService(body: any, user: any, service) {
    this.userData = user;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.patch(this.baseURL + 'service/' + service._id, body, {headers}).toPromise().then((res) => {
      return res;
    })
  }

  // api call to login
  signinUser(email: string, password: string) {
    return this.http.post(this.baseURL + 'login/', {email: email, password: password}).toPromise().then(async (res: any) => {
      this.userData.auth = {
        token: res.token,
        refreshToken: res.refreshToken,
        data: res.user
      }
      if (res.user.role === 'Super Admin') {
        await this.storage.saveData('pulse-right', JSON.stringify(this.userData));
        await this.SelectedOrgGrpId(JSON.parse(localStorage.getItem('organizationGroup')).data._id, res.user.organization[0]._id);
        await this.getSelectedOrgId(JSON.parse(localStorage.getItem('organizationGroup')).data.organization[0]._id);
        this.menuLoader.next('OrgDetails');
      } else {
        await this.storage.saveData('pulse-right', JSON.stringify(this.userData));
        await this.SelectedOrgGrpId(JSON.parse(localStorage.getItem('organizationGroup')).data._id, res.user.organization[0].organizationId._id);
        await this.getSelectedOrgId(res.user.organization[0].organizationId._id);
      }
      // await this.storage.saveData('pulse-right', JSON.stringify(this.userData));
      // await this.SelectedOrgGrpId(JSON.parse(localStorage.getItem('organizationGroup')).data._id, res.user.organization[0]._id);
      // await this.getSelectedOrgId(res.user.organization[0]._id);
      return res;
    }).catch(err => {
      return err;
    });
  }

// storing the default organization Id
  async getSelectedOrgId(orgId) {
    this.userData.SelectedOrg = orgId;
    await this.storage.saveData('pulse-right', JSON.stringify(this.userData));
  }

  // storing the default organizationGroup Id
  async SelectedOrgGrpId(grpId, orgId) {
    this.userData.SelectedOrgGrp = grpId;
    this.userData.SelectedOrg = orgId;
    await this.storage.saveData('pulse-right', JSON.stringify(this.userData));
  }

// api call to get the otp for forgot password
  forgotPassword(number: string) {
    return this.http.post(this.baseURL + 'login/forgotPassword', {number: number}).toPromise().then((resp: any) => {
      return resp;
    });
  }


  // api call for login through phone
  login_phone(phonenumer: string) {
    return this.http.post(this.baseURL + 'login/loginPhone', {number: phonenumer}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

  // api call for verifying the otp
  verifyOtp(number: string, otp: string) {
    return this.http.post(this.baseURL + 'login/verifyOtp', {number: '+91' + number, otp: otp}).toPromise().then(async (res: any) => {
      this.userData.auth = {
        token: res.token,
        refreshToken: res.refreshToken,
        userId: res.user._id,
        data: res.user
      }
      await this.storage.saveData('pulse-right', JSON.stringify(this.userData));
      return this.userData;
    }).catch(err => {
      return err;
    });
  }

// api call to verify the otp required to change the password
  changePassword(body) {
    return this.http.post(this.baseURL + 'login/changePassword/verifyOtp', body).toPromise().then(async (res: any) => {
      await this.storage.saveData('pulse-right', JSON.stringify(res));
    });
  }

  // api call to fetch the Doctor's timeOff Details

  async fetchDoctorTimeoff(user, doctor) {
    this.userData = user;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return await this.http.get(this.baseURL + 'timeoff/getSpecificTimeOffs/' + doctor._id, {headers}).toPromise().then(async (timeoffList) => {
      this.userData.timeOffList = timeoffList;
      await this.storage.saveData('pulse-right', JSON.stringify(this.userData));
      return timeoffList;
    });
  }

  // api call to fetch the Doctor's Over Time Details

  async fetchDoctorCustomTimeoff(user, doctor) {
    this.userData = user;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return await this.http.get(this.baseURL + 'customTime/getSpecificCustomTimeOffs/' + doctor._id, {headers}).toPromise().then(async (timeoffList) => {
      this.userData.overTime = timeoffList;
      await this.storage.saveData('pulse-right', JSON.stringify(this.userData));
      return timeoffList;
    });
  }

  // api call to create timeOff

  async createTimeoff(timeOffData) {
    const body = timeOffData;
    body.organization = this.userData.SelectedOrg;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.userData.auth.token + ''
    });
    return await this.http.post(this.baseURL + 'timeoff/create', body, {headers}).toPromise().then(async () => {
    });
  }

  // api call create Overtime for Doctor

  async createCustTimeoff(timeOffData) {
    const body = timeOffData;
    body.organization = this.userData.SelectedOrg;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.userData.auth.token + ''
    });
    return await this.http.post(this.baseURL + 'customTime/create', body, {headers}).toPromise().then(async () => {
    });
  }

// api call delete the timeoff for doctor
  async deleteTimeoff(timeOffId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.userData.auth.token + ''
    });
    return this.http.delete(this.baseURL + 'timeoff/deletetimeOff/' + timeOffId, {headers}).toPromise().then(async (res) => {
    });
  }

// api call to delete the overtime for doctor
  async deleteCustTimeoff(timeOffId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.userData.auth.token + ''
    });
    return this.http.delete(this.baseURL + 'customTime/deleteCustomTimeOff/' + timeOffId, {headers}).toPromise().then(async (res) => {
    });
  }

  // api call to fetch all docotors
  async fetchAllDoctors(user, org: any) {
    this.userData = user;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return await this.http.get(this.baseURL + 'users/v3/org/Doctor/' + user.SelectedOrg, {headers}).toPromise().then(async (doctorList: any) => {
      if (doctorList.status === 404) {
        this.userData.doctorList = [];
      } else {
        this.userData.doctorList = doctorList.results.filter((doctor) => {
          return doctor.status === true;
        });
      }
      await this.storage.saveData('pulse-right', JSON.stringify(this.userData));
      return doctorList.status === 404 ? [] : doctorList;
    }).catch(err => {
      return err;
    })
  }

  // api call to fetch all docotors with pagination
  async loadDoctorsPagination(user, org: any, page = 1, doctor = '') {
    this.userData = user;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    if (doctor === '') {
      return await this.http.get(this.baseURL + 'users/v3/org/Doctor/' + org + '?page=' + page + '&limit=10', {headers}).toPromise().then(async (doctorList: any) => {
        return doctorList;
      }).catch(err => {
        return err;
      })
    } else {
      return await this.http.get(this.baseURL + 'users/v3/org/Doctor/' + org + '?page=' + page + '&limit=10' + '&name=' + doctor, {headers}).toPromise().then(async (doctorList: any) => {
        return doctorList;
      }).catch(err => {
        return err;
      })
    }

  }

// api call to fetch all appointments by date

  async fetchAllAppointmentsByDate(user, start: string, end: string) {
    this.userData = user;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return await this.http.get(this.baseURL + 'booking/org/' + moment(start).format().split('+')[0] + '/' + moment(end).format().split('+')[0] + '/' + this.userData.SelectedOrg, {headers}).toPromise().then(async (appointmentList) => {
      await this.storage.saveData('pulse-right', JSON.stringify(this.userData));
      return appointmentList;
    });
  }


  // api to list the video consultation of a doctor

  // async fetchVideo_appointments_by_date(user, start: string, end: string, page = 1) {
  //   this.userData = user;
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': 'Bearer ' + user.auth.token + ''
  //   });
  //   return await this.http.get(this.baseURL + 'booking/getVideoConsultation/' + user.auth.data._id + '/' + moment(start).format().split('+')[0] + '/' + moment(end).format().split('+')[0] + '/'
  //     + this.userData.SelectedOrg + '?page=' + page + '&limit=10', {headers}).toPromise().then(async (appointmentList) => {
  //     await this.storage.saveData('pulse-right', JSON.stringify(this.userData));
  //     return appointmentList;
  //   });
  // }

// fetch appointments by organization
  async fetchAllAppointmentsByOrg(user) {
    this.userData = user;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return await this.http.get(this.baseURL + 'booking/org/' + this.userData.SelectedOrg, {headers}).toPromise().then((appointmentList: any) => {
      return appointmentList;
    });
  }

// api to dashboard statistics for particular organization

  async dashboard_stats(organization: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return await this.http.get(this.baseURL + 'users/dashboard/stats/' + organization, {headers}).toPromise().then(async (resp: any) => {
      this.userData.dashboard = resp;
      await this.storage.saveData('pulse-right', JSON.stringify(this.userData));
      return resp;
    });
  }


  // fetch patients by organization by pagination
  async fetchAllPatients(user, pageNum) {
    this.userData = user;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return await this.http.get(this.baseURL + 'users/v3/org/Patient/' + user.SelectedOrg + '?page=' + pageNum + '&limit=10', {headers}).toPromise()
      .then((patientList) => {
        return patientList;
      });
  }

// fetch all staff by organization with pagination
  async fetchAllStaff(user, page = 0, staff = '') {
    this.userData = user;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    if (page === 0) {
      return await this.http.get(this.baseURL + 'users/v3/org/Staff/' + user.SelectedOrg, {headers}).toPromise().then(async (staffList: any) => {
        return staffList;
      });
    } else if (staff === '') {
      return await this.http.get(this.baseURL + 'users/v3/org/Staff/' + user.SelectedOrg + '?page=' + page + '&limit=10', {headers}).toPromise().then(async (staffList: any) => {
        return staffList;
      });
    } else {
      return await this.http.get(this.baseURL + 'users/v3/org/Staff/' + user.SelectedOrg + '?page=' + page + '&limit=10' + '&name=' + staff, {headers}).toPromise().then(async (staffList: any) => {
        return staffList;
      });
    }
  }

// fetch a patient by phone number
  async fetchPatientByPhone(user, phone) {
    this.userData = user;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return await this.http.get(this.baseURL + 'users/phone/' + phone + '/' + user.SelectedOrg, {headers}).toPromise().then(resp => {
      return resp;
    }).catch(err => {
      return err;
    });
  }


  // api call to create a global service
  createService(service, user) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    const body = {
      name: service.name,
      organization: this.userData.SelectedOrg,
      message: service.message
    }
    return this.http.post(this.baseURL + 'service/create', body, {headers}).toPromise().then((res) => {
      return res;
    });
  }

  // api call to delete patient

  deletePatient(patientId, user) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.delete(this.baseURL + 'users/' + patientId, {headers}).toPromise().then((res) => {
      return res;
    });
  }


  serachPatient(user, name) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.get(this.baseURL + 'users/getPatientByName/' + name + '/' + user.SelectedOrg, {headers}).toPromise().then((res) => {
      return res;
    }).catch(err => {
      return err;
    });
  }


  deleteService(serviceId, user) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.delete(this.baseURL + 'service/' + serviceId, {headers}).toPromise().then((res) => {
      return res;
    });
  }


  deleteDoctor(doctorId, user) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.delete(this.baseURL + 'users/' + doctorId, {headers}).toPromise().then((res) => {
      return res;
    });
  }


  AddDependents(body, userData, parentId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.auth.token + ''
    });

    return this.http.post(this.baseURL + 'users/dependent/register/' + parentId, body, {headers}).toPromise().then((res: any) => {
      return res;
    }).catch(err => {
      return err;
    })

  }

  async fetchAllServices(user, page = 1, limit = 20000, serviceName = '') {
    this.userData = user;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });

    if (serviceName === '') {
      return await this.http.get(this.baseURL + 'service/' + this.userData.SelectedOrg +
        '?page=' + page + '&limit=' + limit, {headers}).toPromise()
        .then(async (serviceList: any) => {
          this.userData.serviceList = serviceList.results;
          await this.storage.saveData('pulse-right', JSON.stringify(this.userData));
          return serviceList;
        });
    } else {
      return await this.http.get(this.baseURL + 'service/' + this.userData.SelectedOrg +
        '?page=' + page + '&limit=' + limit + '&name=' + serviceName, {headers}).toPromise()
        .then(async (serviceList: any) => {
          this.userData.serviceList = serviceList.results;
          await this.storage.saveData('pulse-right', JSON.stringify(this.userData));
          return serviceList;
        });
    }

  }


  async fetchUserData(user) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return await this.http.get(this.baseURL + 'users/v3/' + user.auth.data._id + '?useForMobileApp=false', {headers}).toPromise().then(resp => {
      return resp;
    });
  }

  async fetchDoctor(user, doctor) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return await this.http.get(this.baseURL + 'users/v3/' + doctor._id + '?useForMobileApp=false', {headers}).toPromise().then(resp => {
      return resp;
    });
  }

  cancelAppointment(appointmentId, user) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.delete(this.baseURL + 'booking/' + appointmentId, {headers}).toPromise().then((res) => {
      return res;
    });
  }


  confirmBooking(body, user, appointmentId: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    // console.log(appointmentId);
    return this.http.patch(this.baseURL + 'booking/' + appointmentId, body, {headers}).toPromise().then((res) => {
      return res;
    })
  }

  logout() {
    localStorage.removeItem('pulse-right');
    localStorage.removeItem('SelectedOrgGroup');
    localStorage.removeItem('pharmacy');
    localStorage.removeItem('ads');
    clearTimeout(this.INACTIVITY_PERIOD);
    localStorage.removeItem('OrganizationGroupList');
    history.pushState(null, '', location.href);
    window.onpopstate = () => {
      history.go(1);
    }
    // const logoutStatus = new Promise<void>((resolve, reject) => {
    //   this.storage.clearData();
    //   resolve();
    // });
    // return logoutStatus;
  }

  editUserService(userService, body, user) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.patch(this.baseURL + 'service/userService/update/' + userService._id, body, {headers}).toPromise().then((res) => {
      return res;
    })
  }


  async isAuthenticated() {
    if (JSON.stringify(this.userData.auth) !== '{}') {
      return true;
    } else {
      return false;
    }
  }

  create_Queue(user: any, body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.post(this.baseURL + 'queue/create', body, {headers}).toPromise().then((res) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  fetch_queue_for_Doctor(user: any, doctor: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.get(this.baseURL + 'queue/listDoctorQueue/' + user.SelectedOrg + '/' + doctor._id, {headers}).toPromise().then((res) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  reOrder_que(user, body) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.patch(this.baseURL + 'queue/updatePositions', body, {headers}).toPromise().then((res) => {
      return res;
    }).catch(err => {
      return err;
    });
  }


  // api call to create module
  create_module(user: any, body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.post(this.baseURL + 'modules/create', body, {headers}).toPromise().then((res) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  // fetch all modules
  fetch_modules(user: any, page = 1, limit = 20000) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.get(this.baseURL + 'modules/listAll/modules?page=' + page + '&pageSize=' + limit, {headers}).toPromise().then((res) => {
      return res;
    }).catch(err => {
      return err;
    });
  }


  // api to enable the modules for organization
  enable_disable_modules_for_Org(user: any, orgId: any, body: any, removeModule = 'false') {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    if (removeModule === 'false') {
      return this.http.post(this.baseURL + 'org/enableAndDisable/modules/' + orgId, body, {headers}).toPromise().then((res) => {
        return res;
      }).catch(err => {
        return err;
      });
    } else {
      return this.http.post(this.baseURL + 'org/enableAndDisable/modules/' + orgId + '?removeModule=' + removeModule, body, {headers}).toPromise().then((res) => {
        return res;
      }).catch(err => {
        return err;
      });
    }

  }


  // api to delete the module

  deleteModule(user: any, module: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.delete(this.baseURL + 'modules/' + module._id, {headers}).toPromise().then((res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  // api to update the module

  update_module(user: any, module: any, body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.patch(this.baseURL + 'modules/' + module._id, body, {headers}).toPromise().then((res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  // get all Organization groups
  async fetchAll_OrgGroups(user: any, page = 1, limit = 20000) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.get(this.baseURL + 'org/groups/organizationGroups' + '?page=' + page + '&limit=' + limit, {headers}).toPromise().then(async (res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  // create organization Group

  createOrganization_group(user: any, body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.post(this.baseURL + 'org/create/organizationGroup', body, {headers}).toPromise().then((res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

// api call to create an organization
  createOrganization(user: any, body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.post(this.baseURL + 'org/register', body, {headers}).toPromise().then((res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  // api call to upload the logo for the organization
  uploadLogos(user: any, type: string, file: any, selectedOrg) {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    const formData = new FormData();
    formData.append(type, file, file.name)
    return this.http.post(this.baseURL + 'org/upload/' + type + '/' + selectedOrg._id, formData, {headers}).toPromise().then((res: any) => {
        return res;
      }
    ).catch(err => {
    });
  }

  // add organization to OrganizationGroup
  add_Organization_toGrp(user: any, body: any, orgGrpId: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.post(this.baseURL + 'org/addOrganizations/' + orgGrpId._id, body, {headers}).toPromise().then((res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  // delete Organization from the Group

  delete_Organization(user: any, orgGrp: any, org: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.delete(this.baseURL + 'org/removeOrganization/'
      + orgGrp._id + '/' + org._id, {headers}).toPromise().then((res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  // delete Organization group

  delete_OrganizationGroup(user: any, orgGrp: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.delete(this.baseURL + 'org/deleteOrgGroup/'
      + orgGrp._id, {headers}).toPromise().then((res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  // api call to update organization details
  update_org_details(user: any, body: any, org: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.patch(this.baseURL + 'org/'
      + org._id, body, {headers}).toPromise().then((res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

// fetch organization by id
  fetch_organization_Id(user: any, organization: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.get(this.baseURL + 'org/' + organization, {headers}).toPromise().then((res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  // updateOrgGrp details

  update_orgGrp_details(user: any, body: any, orgGrp: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.patch(this.baseURL + 'org/updateOrgGrp/'
      + orgGrp._id, body, {headers}).toPromise().then((res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

// fetch billing list filter of year and month
  fetch_billing_Org(user: any, org: string, page = 1, year = new Date().getFullYear().toString()) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.get(this.baseURL + 'billing/org/' + org + '?year=' + year + '&page=' + page + '&pageSize=10', {headers}).toPromise().then((res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  // get bills of particular year
  fetch_billingOrg_month_year(user: any, org: string, year: string, month: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.get(this.baseURL + 'billing/organisation/' + org + '/month/' + year + '/' + month, {headers}).toPromise().then((res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

// fetch list of admins or Manager for the orhanization
  fetchAdmins(user: any, orgId: any, role: string, userType: string, page = 1) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.get(this.baseURL + 'users/listUsers/roles?orgId=' + orgId + '&role=' + role + '&userType=' + userType + '&page=' + page + '&limit=10', {headers}
    ).toPromise().then((res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }


  // updating the profile picture
  addProfilePic(user: any, file: any, doctor: any) {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + user.auth.token + ''
    });

    const formData = new FormData();
    formData.append('avatar', file, file.name);
    return this.http.post(this.baseURL + 'users/upload/' + doctor._id, formData, {headers}).toPromise().then((res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  // api call to upload doctor signature
  uploadDoctoSignature(user, file, doctor) {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    const formData = new FormData();
    formData.append('signature', file, file.name);
    return this.http.post(this.baseURL + 'users/doctorSignature/uploadForPrescription/' + doctor._id, formData, {headers}).toPromise().then((res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

// api call to remove the patient from the queue
  removeFromQueue(queue: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(this.baseURL + 'queue/complete-queue/' + queue._id, {}, {headers}).toPromise().then((res: any) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

  // updating the promo code

  addPromoCode(user: any, body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.post(this.baseURL + 'modules/createOrUpdatePromoCode', body, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

// api call to create a global rule
  createDue_date(user: any, body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.post(this.baseURL + 'modules/globalRules', body, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

  // list the global rules
  list_due_Dates(user: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.get(this.baseURL + 'modules/listGlobalrules', {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

  // update details of global rules

  update_due_date(user: any, body: any, globalruleId: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.patch(this.baseURL + 'modules/edit/' + globalruleId._id, body, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

  // api call to delete global rule
  deleteGlobalRule(user: any, globalruleId: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.delete(this.baseURL + 'modules/delete/' + globalruleId._id, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }


  //  api call to fetch a specific organization group
  fetchSpecificOrgGrp(user: any, orgGrp: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });

    return this.http.get(this.baseURL + 'org/getSpecificOrganizationGroups/' + orgGrp, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

  // create pharmacy
  createPharmacy(user: any, body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.post(this.baseURL + 'pharmacy/create', body, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

  // api to list all pharmacy filter by organization group using query
  fetchPharmacies(user: any, OrgGrp: any, type: string, page = 1, limit = 20000, dynamicOrg = '') {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    if (dynamicOrg !== '') {
      return this.http.get(this.baseURL + 'pharmacy/listAll?orgId=' + dynamicOrg + '&page=' + page + '&limit=' + limit, {headers}).toPromise().then((resp: any) => {
        return resp;
      }).catch(err => {
        return err;
      })
    }

    if (type === 'organization') {
      return this.http.get(this.baseURL + 'pharmacy/listAll?orgId=' + user.SelectedOrg + '&page=' + page + '&limit=' + limit, {headers}).toPromise().then((resp: any) => {
        return resp;
      }).catch(err => {
        return err;
      })
    }
    if (type !== 'organization') {
      return this.http.get(this.baseURL + 'pharmacy/listAll?orgGrpId=' + OrgGrp._id + '&page=' + page + '&limit=' + limit, {headers}).toPromise().then((resp: any) => {
        return resp;
      }).catch(err => {
        return err;
      });
    }

  }

  // api to update pharmacy Details
  updatePharmacy(user: any, body: any, pharmacy: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.patch(this.baseURL + 'pharmacy/updateSpecific/' + pharmacy._id, body, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

  // delete pharmacy api call
  deletePharmacy(user: any, pharmacy: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.delete(this.baseURL + 'pharmacy/' + pharmacy._id, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

  // api call to create global products
  createGlobal_prods(user: any, body: any, orgGrp: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.post(this.baseURL + 'pharmacyProducts/create/' + orgGrp._id, body, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

  // api to ger all globalProducts based on Organization Group
  fetch_globalProducts(user: any, orgGrp: any, pagenum: number, limit = 20000, productName = '') {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    if (productName === '') {
      return this.http.get(this.baseURL + 'pharmacyProducts/globalProducts/' + orgGrp._id + '?page=' + pagenum + '&limit=' + limit, {headers}).toPromise().then((resp: any) => {
        return resp;
      }).catch(err => {
        return err;
      })
    } else {
      return this.http.get(this.baseURL + 'pharmacyProducts/globalProducts/' + orgGrp._id + '?page=' + pagenum + '&limit=' + limit + '&productName=' + productName, {headers}).toPromise().then((resp: any) => {
        return resp;
      }).catch(err => {
        return err;
      })
    }
  }

  // api call to upload the product Image
  uploadProductImage(user: any, file: any, globalProd: any) {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    const formData = new FormData();
    formData.append('globalProduct', file, file.name);
    return this.http.post(this.baseURL + 'pharmacyProducts/uploadProduct/' + globalProd._id, formData, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

  // api to update global Products
  updateGlobalProducts(user: any, body: any, globalProduct: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.patch(this.baseURL + 'pharmacyProducts/' + globalProduct._id, body, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

  // api to delete globalProducts
  deleteGlobalProducts(user: any, globalProduct: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.delete(this.baseURL + 'pharmacyProducts/' + globalProduct._id, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }


  //  api to create category for pharmacyProducts
  createProd_Category(user: any, body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.post(this.baseURL + 'pharmacyProducts/createCategory', body, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

  // api call get list of all category based on the organization group
  fetchProd_categories(user: any, orgGrp: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.get(this.baseURL + 'pharmacyProducts/listCategory/' + orgGrp._id, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

  // api to delete category
  deleteCategory(user: any, category: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.delete(this.baseURL + 'pharmacyProducts/deleteCategory/' + category._id, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

  // api call to create pharmacy products

  createPharmacy_Product(user: any, body, organizationId: string, globalProduct: any, pharmacy: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.post(this.baseURL + 'pharmacyProducts/addProductToPharmacy/'
      + organizationId + '/' + globalProduct._id + '/' + pharmacy._id, body, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

  // api call to list pharmacy products

  fetchPharmacy_Products(user: any, pharmacy: any, orgGrp: any, pagenum = 1, limit = 20000, productName = '') {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    if (productName === '') {
      return this.http.get(this.baseURL + 'pharmacyProducts/listSpecific/' + orgGrp._id + '/' + pharmacy._id + '?page=' + pagenum + '&limit=' + limit, {headers}).toPromise().then((resp: any) => {
        return resp;
      }).catch(err => {
        return err;
      })
    } else {
      return this.http.get(this.baseURL + 'pharmacyProducts/listSpecific/' + orgGrp._id + '/' + pharmacy._id + '?page=' + pagenum + '&limit=' + limit + '&name=' + productName, {headers}).toPromise().then((resp: any) => {
        return resp;
      }).catch(err => {
        return err;
      })
    }

  }

  // api to update Pharmacy products
  updatePharmacy_products(user: any, body, orgGrp: any, product: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.patch(this.baseURL + 'pharmacyProducts/updatePharmacyData/' + orgGrp._id + '/' + product._id, body, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

  // api call to delete pharmacy product
  deletePharmacy_product(user: any, orgGrp: any, product: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });

    return this.http.delete(this.baseURL + 'pharmacyProducts/deletePharmacyData/' + orgGrp._id + '/' + product._id, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

// api to upload pharmacy Products
  uploadProductCSV(user: any, pharmacy: any, orgGrp: any, file: any) {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + user.auth.token + ''
    });

    const formData = new FormData();
    formData.append('pharmacyProductsFile', file, file.name);

    return this.http.post(this.baseURL + 'csv/uploadPharmacyProducts/' + pharmacy._id + '/' + orgGrp._id, formData, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

  uploadGlobalProducts(user: any, orgGrp: any, file: any) {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + user.auth.token + ''
    });

    const formData = new FormData();
    formData.append('globalProductsFile', file, file.name);

    return this.http.post(this.baseURL + 'csv/uploadGlobalProducts/' + orgGrp._id, formData, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }


  // api to create attendance
  creatAttendance(user: any, organization: any, status: string, body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.post(this.baseURL + 'attendance/addCheckIn-out/' + user.auth.data._id + '/' + organization + '/' + status, body, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }


  // api to get attendance based on the user

  fetchAttendance_userId(user: any, userData: any, page = 0) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    if (page === 0) {
      return this.http.get(this.baseURL + 'attendance/userAttendance/' + userData._id, {headers}).toPromise().then((resp: any) => {
        return resp;
      }).catch(err => {
        return err;
      })
    } else {
      return this.http.get(this.baseURL + 'attendance/userAttendance/' + userData._id + '?page=' + page + '&pageSize=10', {headers}).toPromise().then((resp: any) => {
        return resp;
      }).catch(err => {
        return err;
      })
    }

  }


  // api to get dashboard status to see number if checkIn and checkouts

  async number_of_checkIns_CheckOuts(user: any, oranization: any, date: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });

    return this.http.get(this.baseURL + 'attendance/dashBoard/managerView/' + oranization + '/' + moment(date).format().split('+')[0], {headers}).toPromise().then(async (resp: any) => {
      this.userData.managerDashboard = resp;
      await this.storage.saveData('pulse-right', JSON.stringify(this.userData));
      return resp;
    }).catch(err => {
      return err;
    })
  }

  // api to list the manager based on organizations
  fetchManagersByOrg(user: any, organization: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.get(this.baseURL + 'leave/listManagers/' + organization, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

  // api for apply for leave

  applyForLeave(user: any, body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.post(this.baseURL + 'leave/applyLeave', body, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

  // api tp fetch leaves for the particular user

  fetchLeave_user(user: any, organization: string, page = 1, userType = 'Staff', status = 'all', name = '') {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    if (name !== '') {
      return this.http.get(this.baseURL + 'leave/getAll/leaves/' + organization + '?page=' + page + '&limit=10&userType=' + userType + '&name=' + name + '&status=' + status, {headers}).toPromise().then((resp: any) => {
        return resp;
      }).catch(err => {
        return err;
      })
    }

    if (status === 'all') {
      if (userType !== '') {
        if (user.auth.data.userType === 'Manager' || user.auth.data.userType === 'Admin') {
          return this.http.get(this.baseURL + 'leave/getAll/leaves/' + organization + '?page=' + page + '&limit=10&userType=' + userType, {headers}).toPromise().then((resp: any) => {
            return resp;
          }).catch(err => {
            return err;
          })
        }
      } else {
        return this.http.get(this.baseURL + 'leave/getAll/leaves/' + organization + '?userId=' + user.auth.data._id + '&page=' + page + '&limit=10&userType=Manager', {headers}).toPromise().then((resp: any) => {
          return resp;
        }).catch(err => {
          return err;
        })
      }
    } else {
      if (userType !== '') {
        if (user.auth.data.userType === 'Manager' || user.auth.data.userType === 'Admin') {
          return this.http.get(this.baseURL + 'leave/getAll/leaves/' + organization + '?page=' + page + '&limit=10&userType=' + userType + '&status=' + status, {headers}).toPromise().then((resp: any) => {
            return resp;
          }).catch(err => {
            return err;
          })
        }
      } else {
        return this.http.get(this.baseURL + 'leave/getAll/leaves/' + organization + '?userId=' + user.auth.data._id + '&page=' + page + '&limit=10&userType=Manager' + '&status=' + status, {headers}).toPromise().then((resp: any) => {
          return resp;
        }).catch(err => {
          return err;
        })
      }
    }
  }

  // load Staff Leaves

  loadStaff_leaver(user, org, page, status) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.get(this.baseURL + 'leave/getAll/leaves/' + org + '?userId=' + user.auth.data._id + '&page=' + page + '&limit=10&userType=Staff' + '&status=' + status, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

  // api call to fetch staff and manager leaves with pagination
  loadMyLeaves(user: any, organization: any, page = 1, status = '') {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    if (status !== '') {
      return this.http.get(this.baseURL + 'leave/getAll/leaves/' + organization + '?userId=' + user.auth.data._id + '&page=' + page + '&limit=10' + '&status=' + status, {headers}).toPromise().then((resp: any) => {
        return resp;
      }).catch(err => {
        return err;
      })
    } else {
      return this.http.get(this.baseURL + 'leave/getAll/leaves/' + organization + '?userId=' + user.auth.data._id + '&page=' + page + '&limit=10', {headers}).toPromise().then((resp: any) => {
        return resp;
      }).catch(err => {
        return err;
      })
    }

  }

  // api to approve the leave

  approveLeave(user: any, body: any, leave: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.patch(this.baseURL + 'leave/' + leave._id, body, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

  // api to delete the leave record

  deleteLeave(user: any, leave: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.delete(this.baseURL + 'leave/' + leave._id, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

  // api to list all reports based on the userId

  listPatient_report(user: any, patient: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.get(this.baseURL + 'users/listUserReports/' + patient._id, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

  // api to add medical report for the users

  uploadLabReport(user: any, patient: any, reportName: string, file: any) {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    const formData = new FormData();
    formData.append('reports', file, file.name);

    return this.http.post(this.baseURL + 'users/uploadMedicalReports/' + patient._id + '/' + reportName, formData, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })

  }


  // api to add new prescription

  addPrescription(user: any, body: any, patient: any, doctor: any, orgId: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.post(this.baseURL + 'prescription/addPrescription/' + patient._id + '/' + doctor._id + '/' + orgId, body, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

  // api to get list of prescription based on organization and Doctor for a specific patient

  fetchPrescription_patient(user: any, patient: any, organizationId: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });

    return this.http.get(this.baseURL + 'prescription/getPrescriptions/' + organizationId + '?patientId=' + patient._id, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

// api to add medication from the doctor for a created prescription

  addMedicine_Prescription(user: any, body: any, prescription: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.post(this.baseURL + 'prescription/addMedication/byDoctor/' + prescription._id, body, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

  // api to delete prescription

  deletePrescription(user: any, prescription: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.delete(this.baseURL + 'prescription/' + prescription._id, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

// api to edit the basic details of the prescriptions

  updatePreScription_Details(user: any, prescription: any, body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.patch(this.baseURL + 'prescription/' + prescription._id, body, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

  // api to edit the basic details of the prescriptions medicine Details

  update_Prescription_medicine(user: any, prescription: any, medicine: any, body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.patch(this.baseURL + 'prescription/updateMedicationData/' + prescription._id + '/' + medicine._id, body, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

// api to delete the particular medication from the prescription

  delete_Prescription_medicine(user: any, prescription: any, medicine: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.delete(this.baseURL + 'prescription/deleteMedication/' + prescription._id + '/' + medicine._id, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }


// api to place order
  placeOrder(user: any, prescription: any, body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.post(this.baseURL + 'orders/placeOrder/' + prescription._id, body, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }


  // api to fetch order by status
  fetchOrder_status(user: any, status = '', page = 1, pharmacy: any, searchType = '', searchValue = '') {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });


    if (status !== '' && searchValue !== '') {
      return this.http.get(this.baseURL + 'orders/listOrders?pharmacyId=' + pharmacy._id + '&' + searchType + '=' + searchValue + '&page=' + page + '&orderStatus=' + status, {headers}).toPromise().then((resp: any) => {
        return resp;
      }).catch(err => {
        return err;
      })
    }

    if (searchType === '') {
      return this.http.get(this.baseURL + 'orders/listOrders?orderStatus=' + status + '&page=' + page + '&limit=10&pharmacyId=' + pharmacy._id, {headers}).toPromise().then((resp: any) => {
        return resp;
      }).catch(err => {
        return err;
      })
    }
    if (searchType !== '') {
      return this.http.get(this.baseURL + 'orders/listOrders?pharmacyId=' + pharmacy._id + '&' + searchType + '=' + searchValue + '&page=' + page, {headers}).toPromise().then((resp: any) => {
        return resp;
      }).catch(err => {
        return err;
      })
    } else {
      return this.http.get(this.baseURL + 'orders/listOrders?pharmacyId=' + pharmacy._id + '&' + searchType + '=' + searchValue + '&page=' + page, {headers}).toPromise().then((resp: any) => {
        return resp;
      }).catch(err => {
        return err;
      })
    }
  }


  // api to update orderStatus

  update_Order_status(user, selectedOrder, status, body = {}) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.post(this.baseURL + 'orders/updateOrderStatus/' + selectedOrder._id + '/' + status, body, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

  // api to fetch OrderBy date
  fetchOrderByDate(user, startDate, endDate, pharmacy, page = 1, orderStatus = '') {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });

    if (orderStatus === '') {
      return this.http.get(this.baseURL + 'orders/getOrdersByDateRange/' + pharmacy._id + '/' + user.SelectedOrg +
        '/' + moment(startDate).format().split('+')[0] + '/'
        + moment(endDate).format().split('+')[0] + '?page=' + page + '&limit=10', {headers}).toPromise().then((resp: any) => {
        return resp;
      }).catch(err => {
        return err;
      });
    } else {
      return this.http.get(this.baseURL + 'orders/getOrdersByDateRange/' + pharmacy._id + '/' + user.SelectedOrg +
        '/' + moment(startDate).format().split('+')[0] + '/'
        + moment(endDate).format().split('+')[0] + '?page=' + page + '&limit=10' + '&orderStatus=' + orderStatus, {headers}).toPromise().then((resp: any) => {
        return resp;
      }).catch(err => {
        return err;
      });
    }
  }

  // api to update OrderedItems

  updateOrderedItems(user, body, medicine, order, remove = 'false') {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    if (remove === 'false') {
      return this.http.patch(this.baseURL + 'orders/updateOrderItem/' + order._id + '/' + medicine._id, body, {headers}).toPromise().then((resp: any) => {
        return resp;
      }).catch(err => {
        return err;
      });
    } else {
      return this.http.patch(this.baseURL + 'orders/updateOrderItem/' + order._id + '/' + medicine._id + '?remove=' + remove, body, {headers}).toPromise().then((resp: any) => {
        return resp;
      }).catch(err => {
        return err;
      });
    }

  }

  // api to create immunization

  createImmunization(user, patient, body) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.post(this.baseURL + 'immunization/addImmunization/' + patient._id + '/' + user.SelectedOrg, body, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

// fetch patient prescription

  fetchPatientImmunization(user, patient) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.get(this.baseURL + 'immunization/listImmunizations/' + user.SelectedOrg + '?patientId=' + patient._id, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

  // api call to delete immunization

  deleteImmunization(user, immunization, immunizationObject) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.delete(this.baseURL + 'immunization/deleteImmunizationdetail/' + immunization._id + '/' + immunizationObject._id, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

  // update immunization details

  update_Immunization(user, immunization, body) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.patch(this.baseURL + 'immunization/' + immunization._id, body, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

  // api to update global immunization

  updateGlobalImmunization_Details(user, globalImmunization, body) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.patch(this.baseURL + 'immunization/globalImmune/' + globalImmunization._id, body, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }


  // api to get list of all immunization base on organization and filter by user.

  createGlobal_Immunization(user, body) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.post(this.baseURL + 'immunization/global/organizationImmunization/' + user.SelectedOrg, body, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

  // api to get immunization based on organization

  fetchImmunizationByOrg(user) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.get(this.baseURL + 'immunization/globalImmune/list/' + user.SelectedOrg, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }


  // api to delete global immunization details

  deleteGlobalImmunization(user, immunization, immunizationData) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.delete(this.baseURL + 'immunization/globalImmune/deleteGlobalImmunizationdetail/' + immunization._id + '/' + immunizationData._id, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }


  // api to populate organization immunization for the patient for the first time.

  populate_Patient_Immunization(user, patient) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.post(this.baseURL + 'immunization/globalImmunization/populateData/' + patient._id + '/' + user.SelectedOrg, {}, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

  // fetch Pharmacy Admins

  async fetchPharmacy_Admins(user, userType = 'Pharmacy Admin') {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return await this.http.get(this.baseURL + 'users/v3/org/' + userType + '/' + user.SelectedOrg, {headers}).toPromise().then(async (resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

  // api to complete the payment

  async completePayment(user, order, body) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return await this.http.post(this.baseURL + 'orders/completePayment/' + order._id, body, {headers}).toPromise().then(async (resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

  // api call to download the sample csv products
  async downloadFile(user, orgGrp, pharmacy) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return await this.http.get(this.baseURL + 'pharmacyProducts/csvDumpForProducts/' + orgGrp._id + '/' + pharmacy._id, {headers}).toPromise().then(async (resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

  // api call to get the pharmacy statistics like number of orders and number of products

  async fetchPharmacyStatistics(pharmacy, startDate, endDate) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return await this.http.get(this.baseURL + 'orders/orderStatisticsByPharmacy/' + pharmacy._id + '/' + moment(startDate).format().split('+')[0]
      + '/' + moment(endDate).format().split('+')[0], {headers}).toPromise().then(async (resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

// api to add a private note for the pharmacy reference

  async addNote(user, order, body) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return await this.http.post(this.baseURL + 'orders/add-note/' + order._id, body, {headers}).toPromise().then(async (resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }


// api to remove a private note for the pharmacy reference
  async deleteNote(user, order, note) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return await this.http.delete(this.baseURL + 'orders/delete-note/' + order._id + '/' + note._id, {headers}).toPromise().then(async (resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }


  // api to get the list of staff who are on leave for today/currentDate/selectedDate
  async fetchLeavesByDate(user, date: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return await this.http.get(this.baseURL + 'leave/listOfStaff/onLeave/' + user.SelectedOrg + '/' + moment(date).format().split('+')[0], {headers}).toPromise().then(async (resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

  // api to get list of all queues based on the organization and doctor

  async getDoctorQueueList(user) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return await this.http.get(this.baseURL + 'queue/listDoctorQueue/' + user.SelectedOrg + '/' + user.auth.data._id, {headers}).toPromise().then(async (resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

// api To get a list of Dcotors within organization

  async getDoctors_orgId(user, userType, globalService) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return await this.http.get(this.baseURL + 'users/v3/org/' + userType + '/' + user.SelectedOrg + '?serviceId=' + globalService._id, {headers}).toPromise().then(async (resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

// api for getting booking history by date

  async getAppontemntHistory(user, startDate: string, endDate: string, doctor = null, page = 1) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    if (doctor) {
      return await this.http.get(this.baseURL + 'booking/getBookingHistory/' +
        moment(startDate).format().split('+')[0] + '/' + moment(endDate).format().split('+')[0] + '/'
        + user.SelectedOrg + '/filterResponse?doctorId=' + doctor._id + '&page=' + page + '&limit=10', {headers}).toPromise().then(async (resp: any) => {
        return resp;
      }).catch(err => {
        return err;
      });
    } else {
      return await this.http.get(this.baseURL + 'booking/getBookingHistory/' +
        moment(startDate).format().split('+')[0] + '/' + moment(endDate).format().split('+')[0] + '/'
        + user.SelectedOrg + '/filterResponse?page=' + page + '&limit=10', {headers}).toPromise().then(async (resp: any) => {
        return resp;
      }).catch(err => {
        return err;
      });
    }
  }

  // api to get booking count for dashboard(admin and super admin)

  async bookingCount(user: any, SelectedOrg) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return await this.http.post(this.baseURL + 'booking/' + SelectedOrg + '/AdminDashBoard/forBooking', {}, {headers}).toPromise().then(async (resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

  // api to create doctor template

  async createTemplate(user, body) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return await this.http.post(this.baseURL + 'prescription/createTemplate/' + user.auth.data._id + '/' + user.SelectedOrg, body, {headers}).toPromise().then(async (resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

  // api to get doctors template

  async getDoctorTemplates(user) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return await this.http.get(this.baseURL + 'prescription/listTemplates/' + user.auth.data._id + '/' + user.SelectedOrg, {headers}).toPromise().then(async (resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

  // api to update doctor template

  async UpdateDoctorTemplates(user, template, body) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return await this.http.patch(this.baseURL + 'prescription/editTemplates/' + template._id, body, {headers}).toPromise().then(async (resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

  // api call to delete doctor template
  async deleteDoctorTemplate(user, template, parentTemplate) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return await this.http.delete(this.baseURL + 'prescription/removeTemplateDetail/' + parentTemplate._id + '/' + template._id, {headers}).toPromise().then(async (resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

  // api to get the details of leave of a user.

  async getAvailableLeavesCount(user) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return await this.http.get(this.baseURL + 'leave/getDetails/' + user.auth.data._id + '/' + user.SelectedOrg, {headers}).toPromise().then(async (resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

  // api to upload pharmacy logo
  uploadPharmacyLogo(user: any, file: any, pharmacy) {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    const formData = new FormData();
    formData.append('pharmacyLogo', file, file.name)
    return this.http.post(this.baseURL + 'pharmacy/logo/' + pharmacy._id, formData, {headers}).toPromise().then((res: any) => {
        return res;
      }
    ).catch(err => {
    });
  }

// api to get attendance based on selected dates
  async getAttendanceByDate(user, startDate: string, endDate: string, page, staff = null) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    if (!staff) {
      return await this.http.get(this.baseURL + 'attendance/' + user.SelectedOrg + '/' + moment(startDate).format().split('+')[0] + '/'
        + moment(endDate).format().split('+')[0] + '?page=' + page + '&pageSize=10', {headers})
        .toPromise().then(async (resp: any) => {
          return resp;
        }).catch(err => {
          return err;
        });
    } else {
      return await this.http.get(this.baseURL + 'attendance/' + user.SelectedOrg + '/' + moment(startDate).format().split('+')[0] + '/'
        + moment(endDate).format().split('+')[0] + '?userId=' + staff._id + '&page=' + page + '&pageSize=10', {headers})
        .toPromise().then(async (resp: any) => {
          return resp;
        }).catch(err => {
          return err;
        });
    }
  }

  // api call to create department
  async createDepartment(user, body) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return await this.http.post(this.baseURL + 'org/OrgDepartment/create', body, {headers}).toPromise().then(async (resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

  // api call to get departments by organization

  async fetchDepartmentOrg(user, page = 1, limit = 20000, departmentName = '') {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    if (departmentName === '') {
      return await this.http.get(this.baseURL + 'org/OrgDepartment/listDepartments/fromOrg/' + user.SelectedOrg + '?page=' + page + '&pageSize=' + limit, {headers}).toPromise().then(async (response: any) => {
        return response;
      }).catch(err => {
        return err;
      })
    } else {
      return await this.http.get(this.baseURL + 'org/OrgDepartment/listDepartments/fromOrg/' + user.SelectedOrg + '?page=' + page + '&pageSize=' + limit + '&name=' + departmentName, {headers}).toPromise().then(async (response: any) => {
        return response;
      }).catch(err => {
        return err;
      })
    }

  }

  // api to delete department
  async deleteDepartment(user, department) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return await this.http.delete(this.baseURL + 'org/OrgDepartment/delete/' + department._id, {headers}).toPromise().then(async (resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

  // api to get particular prescription

  async getParticular_prescription(user, prescription) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return await this.http.get(this.baseURL + 'prescription/specificPrescription/' + prescription._id, {headers}).toPromise().then(async (resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

// api call to get pharmacy by id
  async fetchPharmacy(user, pharmacy) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return await this.http.get(this.baseURL + 'pharmacy/listSpecific/' + pharmacy, {headers}).toPromise().then(async (resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

  // api call to get specific bill

  async fetchBill(user, bill) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return await this.http.get(this.baseURL + 'billing/' + bill, {headers}).toPromise().then(async (resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

  // api call to update the bill payment

  async updateBill_payment(user, bill, body) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return await this.http.patch(this.baseURL + 'billing/' + bill, body, {headers}).toPromise().then(async (resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

// fetch an appointment by using its id
  async fetchAppointmentById(user, appointment) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return await this.http.get(this.baseURL + 'booking/' + appointment._id, {headers}).toPromise().then(async (resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }


  // api to get the order by orderId
  async fetchOrderById(user, order) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return await this.http.get(this.baseURL + 'orders/getOrder/' + order._id, {headers}).toPromise().then(async (resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }


  // api call to upload doctors using a csv
  async uploadDoctors(user, file) {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    const formData = new FormData();
    formData.append('doctorsCSV', file, file.name);
    return this.http.post(this.baseURL + 'csv/uploadDoctors/forOrganization/' + user.SelectedOrg, formData, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

  async uploadDoctorsPositions(user, file) {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    const formData = new FormData();
    formData.append('positionsFile', file, file.name);
    return this.http.post(this.baseURL + 'csv/uploadPositions/' + user.SelectedOrg, formData, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

  // initiate Call
  async initiateCall(user, body) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.post(this.baseURL + 'users/api/initiate-call', body, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }


  // sms template creation
  async create_SMS_Template(user, body) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.post(this.baseURL + 'bulkSMS/createNew/template', body, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

  // list sms templates

  async list_SMS_Template(user, type = '', status = '') {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    if (type === '' && status === '') {
      return this.http.get(this.baseURL + 'bulkSMS/LisAll/basedOnFilteration?organizationId=' + user.SelectedOrg, {headers}).toPromise().then((resp: any) => {
        return resp;
      }).catch(err => {
        return err;
      });
    }
    if (status === '') {
      return this.http.get(this.baseURL + 'bulkSMS/LisAll/basedOnFilteration?messageType=' + type + '&organizationId=' + user.SelectedOrg, {headers}).toPromise().then((resp: any) => {
        return resp;
      }).catch(err => {
        return err;
      });
    } else {
      return this.http.get(this.baseURL + 'bulkSMS/LisAll/basedOnFilteration?messageType=' + type + '&status=' + status + '&organizationId=' + user.SelectedOrg, {headers}).toPromise().then((resp: any) => {
        return resp;
      }).catch(err => {
        return err;
      });
    }
  }

  // api call to approve template

  async approveTemplate(user, body, template) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.patch(this.baseURL + 'bulkSMS/updateStatus/toApproved/' + template._id, body, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

  // upload csv for sms templates
  async uploadCSv_for_SMSTemplate(user, file, template) {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    const formData = new FormData();
    formData.append('smsNumberCsv', file, file.name);
    return this.http.post(this.baseURL + 'bulkSMS/sendSMS/' + template.messageType + '/' + user.SelectedOrg + '/' + template.smsTemplateName, formData, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

  // delete sms template
  async delete_SMS_template(user, template) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.delete(this.baseURL + 'bulkSMS/' + template._id, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

  // api to edit the time out for the organizations

  async auto_logout_setting(user, body, orgGroupId, orgId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return await this.http.post(this.baseURL + 'org/updateTimeOut/' + orgGroupId + '/' + orgId, body, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

  // timeInterval reset if user moves mouse or touches the key board keys
  resetTimer() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      // Trigger logout logic here
      this.modalService.dismissAll();
      if (location.pathname !== '/queue-list') {
        this.logout();
        this.router.navigate(['/login-admin']);
      }
    }, this.INACTIVITY_PERIOD);
  }

  // api call to get a specific leave
  async fetchLeave_byId(user, leave) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.get(this.baseURL + 'leave/listSpecific/' + leave, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

  async create_campaign(user: any, body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
    });

    return await this.http.post(this.baseURL + 'campaign/create', body, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch((err) => {
      return err;
    })
  }

  async fetch_all_campaigns(user: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
    });
    return await this.http.get(this.baseURL + 'campaign/getAll/campaign?organizationId=' + user.SelectedOrg, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch((err) => {
      return err;
    })
  }


  async fetch_all_campaigns_user(user: any, role: string, userId: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
    });

    if (role === 'Admin') {
      return await this.http.get(this.baseURL + 'campaign/getAll/campaign?organizationId=' + user.SelectedOrg, {headers}).toPromise().then((resp: any) => {
        return resp;
      }).catch((err) => {
        return err;
      })
    } else {
      return await this.http.get(this.baseURL + 'campaign/getAll/campaign?organizationId=' + user.SelectedOrg, {headers}).toPromise().then((resp: any) => {
        return resp;
      }).catch((err) => {
        return err;
      })
    }
  }

  async getAllUsers(user: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
    });
    return await this.http.get(this.baseURL + 'client/getAll/Clients', {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch((err) => {
      return err;
    })
  }

  async fetch_all_clients(user: any, role: string, org = null) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
    });
    if (role === 'Super Admin') {
      return await this.http.get(this.baseURL + 'client/getAll/Clients?organizationId=' + org, {headers}).toPromise().then((resp: any) => {
        return resp;
      }).catch((err) => {
        return err;
      })
    }
    return await this.http.get(this.baseURL + 'client/getAll/Clients?organizationId=' + org, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch((err) => {
      return err;
    })
  }

  // api call to create or register a client
  async create_client(user: any, body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
    });

    return await this.http.post(this.baseURL + 'client/register', body, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch((err) => {
      return err;
    })
  }

  //get specific client
  async get_specific_client(user: any, client: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
    });
    return await this.http.get(this.baseURL + 'client/listSpecific/', {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch((err) => {
      return err;
    })
  }

  async update_campaign_data(user: any, body: any) {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + user.auth.token + '',
    });

    const formData = new FormData();
    formData.append('url', body.file, body.file.name);

    return await this.http.post(this.baseURL
      + 'campaign/add-data/' + body.campaignId + '/' + body.type + '/' + body.time + '/' + body.refreshTime + '/' + body.position + '/' + body.orientation
      , formData, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch((err) => {
      return err;
    })
  }

  async update_imageUrl(user: any, campaign: any, body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
    });

    return await this.http.post(this.baseURL + 'campaign/addData/url/' + campaign._id, body, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch((err) => {
      return err;
    })
  }

  async update_campaign_details(user: any, body: any, campaign: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
    });

    return await this.http.patch(this.baseURL + 'campaign/' + campaign._id, body, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

  async delete_campaign(user: any, campaign: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
    });

    return await this.http.delete(this.baseURL + 'campaign/' + campaign._id, {headers})
      .toPromise().then((resp: any) => {
        return resp;
      }).catch(err => {
        return err;
      })
  }

  async upData_camp_Data(user: any, campaign: any, campData: any, body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
    });
    return await this.http.patch(this.baseURL + 'campaign/editData/' + campaign._id + '/' + campData._id, body, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch((err) => {
      return err;
    })
  }

  async update_url_campaignData(user: any, image: any, campaign: any, data: any) {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + user.auth.token + '',
    });
    const formData = new FormData();
    formData.append('url', image, image.name);

    return await this.http.post(this.baseURL + 'campaign/upload/image/' + campaign._id + '/' + data._id,
      formData, {headers})
      .toPromise().then((resp: any) => {
        return resp;
      }).catch(err => {
        return err;
      })
  }

  async delete_campaign_data(user: any, campaign: any, data: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
    });

    return await this.http.delete(this.baseURL + 'campaign/data/' + campaign._id + '/' + data._id, {headers})
      .toPromise().then((resp: any) => {
        return resp;
      }).catch(err => {
        return err;
      })
  }


  async update_client_campaign(user: any, campaign: any, client: any, body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
    });
    return await this.http.post(this.baseURL + 'client/addCampaign/' + campaign + '/' + client._id, body, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch((err) => {
      return err;
    })
  }

  async update_client(user: any, body: any, client: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
    });
    return await this.http.patch(this.baseURL + 'client/' + client._id, body, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch((err) => {
      return err;
    })
  }

  async update_timings(user: any, body: any, day: string, client: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
    });
    return await this.http.patch(this.baseURL + 'client/' + client._id + '/' + day, body, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch((err) => {
      return err;
    })
  }

  async delete_client(user: any, client: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
    });
    return await this.http.delete(this.baseURL + 'client/' + client._id, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch((err) => {
      return err;
    })
  }

  Event_emit(client: any) {
    this.adds_socket.emit('join-room', client._id);
  }

  onclientSocket(): Observable<any> {
    return new Observable((observer: any) => {
      this.adds_socket?.on('message', (data: any) => {
        observer.next(data);
      });
    });
  }

  async fetch_specific_campaign(user: any, campaign: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
    });

    return await this.http.get(this.baseURL + 'campaign/listSpecific/' + campaign._id, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }


  // generate invoice for the organization for first time
  async generateInvoice(user: any, organization, body = {}) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
    });

    return await this.http.post(this.baseURL + 'billing/createForOrganization/' + organization, body, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

  // api Add or Remove an Organization from the DoctorStaff's organization array

  async addRemoveOrganization(user: any, userId: string, action: string = 'remove', body) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
    });
    return await this.http.post(this.baseURL + 'users/doctorStaff/addOrRemoveOrganization/' + userId + '/' + action, body, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }


  // fetch specific Booking

  async getBookingById(user: any, bookingId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
    });
    return await this.http.get(this.baseURL + 'booking/' + bookingId, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }


// api call to create a new bill
  async create_consultation_bill(user: any, body) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
    });
    return await this.http.post(this.baseURL + 'patientBill/createNewBill', body, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }


  // THE NEW API FOR THE BILLING DASHBOARD
  async dashboard_paitentBill_statistics(user, startDate: string, endDate: string, billType = 'consultation') {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
    });
    return await this.http.get(this.baseURL + 'patientBill/statistics/' + billType + '/' + startDate + '/' + endDate + '?organizationId=' + user.SelectedOrg, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }


  // api to list all the billings based on the billType filter by organization

  async getPatientBill(user: any, bookingId: string = '', queueId: string = '', billType = 'consultation', startDate = '', endDate = '',
                       page = 1, orderId = '', patientId = '') {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
    });
    if (patientId !== '') {
      return await this.http.get(this.baseURL + 'patientBill/listBillings?organizationId=' + user.SelectedOrg + '&userId=' + patientId + '&billType=' + billType +
        '&page=' + page + '&pageSize=10', {headers}).toPromise().then((resp: any) => {
        return resp;
      }).catch(err => {
        return err;
      })
    }
    if (bookingId !== '') {
      return await this.http.get(this.baseURL + 'patientBill/listBillings?organizationId=' + user.SelectedOrg + '&bookingId=' + bookingId + '&billType=' + billType, {headers}).toPromise().then((resp: any) => {
        return resp;
      }).catch(err => {
        return err;
      })
    }
    if (queueId !== '') {
      return await this.http.get(this.baseURL + 'patientBill/listBillings?organizationId=' + user.SelectedOrg + '&queueId=' + queueId + '&billType=' + billType, {headers}).toPromise().then((resp: any) => {
        return resp;
      }).catch(err => {
        return err;
      });
    }
    if (orderId !== '') {
      return await this.http.get(this.baseURL + 'patientBill/listBillings?billType=' + billType + '&orderId=' + orderId, {headers}).toPromise().then((resp: any) => {
        return resp;
      }).catch(err => {
        return err;
      })
    }
    return await this.http.get(this.baseURL + 'patientBill/listBillings?organizationId=' + user.SelectedOrg + '&billType=' + billType + '&startDate=' + startDate + '&endDate=' + endDate + '&page=' + page + '&pageSize=10', {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }


  // api to get th details of the specific bill

  async fetchSpecificBill(user, billId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
    });
    return await this.http.get(this.baseURL + 'patientBill/getSpecificBill/' + billId, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }


  // THE API TO ADD BILLING FOR THE MODULE ENABLED
  async bill_for_moduleEnabled(user: any, body, organization) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
    });
    return await this.http.post(this.baseURL + 'billing/calculateBilling/' + organization, body, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

  // fetch Bills of Organizatiion
  async bills_of_organization_Id(user: any, org) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
    });
    return await this.http.get(this.baseURL + 'billing/org/' + org, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

  // place order from pharmacy

  async placeOrder_pharmacy(user, body) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
    });
    return await this.http.post(this.baseURL + 'orders/addOrderForPharmacy', body, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }


  // assign the Screen Id for the screens

  async assignScreen_Id(user, screenId: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
    });
    return await this.http.post(this.baseURL + 'client/assignOrganization/' + screenId, {orgId: user.SelectedOrg}, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

  // api call to create screen
  async create_queue_screens(user, body) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
    });
    return await this.http.post(this.baseURL + 'queueScreen/createScreen', body, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }


  // api call to list queue screens
  async list_queue_screens(user, page, limit = 10) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
    });
    return await this.http.get(this.baseURL + 'queueScreen/listScreens?orgId=' + user.SelectedOrg + '&page=' + page + '&limit=' + limit, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

  // delete queue screen
  async delete_screen_queue(user, queuescreem_id: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
    });
    return await this.http.delete(this.baseURL + 'queueScreen/deleteQueueScreen/' + queuescreem_id, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

  // create rooms
  async create_room(user, organization, body) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
    });
    return await this.http.post(this.baseURL + 'orgRooms/createRoom/' + organization, body, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

  // fetch rooms
  async fetch_rooms(user, organization, page = 1, dropDown = 'false', dropDownContent = '', roomNumber = '') {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
    });
    if (dropDown === 'true' && dropDownContent !== '') {
      return await this.http.get(this.baseURL + 'orgRooms/getRooms/' + organization + '?dropDown=' + dropDown + '&dropDownContent=' + dropDownContent, {headers}).toPromise().then((resp: any) => {
        return resp;
      }).catch(err => {
        return err;
      })
    }
    if (roomNumber !== '') {
      return await this.http.get(this.baseURL + 'orgRooms/getRooms/' + organization + '?roomNumber=' + roomNumber, {headers}).toPromise().then((resp: any) => {
        return resp;
      }).catch(err => {
        return err;
      })
    }
    return await this.http.get(this.baseURL + 'orgRooms/getRooms/' + organization + '?page=' + page + '&limit=10', {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

  // delete room by id

  async delete_room(user, roomId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
    });
    return await this.http.delete(this.baseURL + 'orgRooms/deleteRoom/' + roomId, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }  // update room by id

  async update_room(user, roomId, body) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
    });
    return await this.http.patch(this.baseURL + 'orgRooms/updateSpecific/' + roomId, body, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

// api to add doctors and remove doctors from the room
  async add_remove_doctor_room(user, roomId, body) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
    });
    return await this.http.post(this.baseURL + 'orgRooms/updateRoomDoctors/' + roomId, body, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    })
  }

  // api to add or remove patient from the room
  async add_remove_patient_room(user, roomId, body) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
    });
    return await this.http.post(this.baseURL + 'orgRooms/updateRoomPatients/' + roomId, body, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

  // api to upload assets for the room
  async upload_room_assets(user, body, file: any, roomId: string) {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + user.auth.token + '',
    });
    const formData = new FormData();
    formData.append('asset', file, file.name)

    return await this.http.post(this.baseURL + 'orgRooms/roomAssets/upload/' + roomId + '?description=' + body.description, formData, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

  // api to list specific rooms
  async fetch_room_by_id(user, roomId: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
    });
    return await this.http.get(this.baseURL + 'orgRooms/getSpecificRoom/' + roomId, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

  // api to delete the assets
  async delete_room_assets(user, roomId: string, assetId: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + '',
    });
    return await this.http.delete(this.baseURL + 'orgRooms/roomAssets/delete/' + roomId + '/' + assetId, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

  // api call to add doctor availability message via socket

  async doctor_availability(orgId: string, body, removeScrollingMessage = 'false') {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    if (removeScrollingMessage === 'true') {
      return await this.http.post(this.baseURL + 'queue/sendScrollingMessage/' + orgId + '?removeScrollingMessage=true&doctorId=' + body.payload.doctorId, body, {headers}).toPromise().then((resp: any) => {
        return resp;
      }).catch(err => {
        return err;
      });
    }
    return await this.http.post(this.baseURL + 'queue/sendScrollingMessage/' + orgId + '?doctorId=' + body.payload.doctorId, body, {headers}).toPromise().then((resp: any) => {
      return resp;
    }).catch(err => {
      return err;
    });
  }

// api to add walkin queue and booking for only appointment model

  async walking_appointments_booking(user, body) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user.auth.token + ''
    });
    return this.http.post(this.baseURL + 'queue/createQueueForAppointmentModule', body, {headers}).toPromise().then((res) => {
      return res;
    }).catch(err => {
      return err;
    });
  }

}
